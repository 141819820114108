import React from 'react';

import Layout from '../layouts/Layout';

const PaymentSettings = () => {

  return (
    <Layout section={'payment'}>
      <section className="section homepage-content">
        <div className="container">
          <>
            <div className="columns is-centered">
              <article className="message is-success">
                <div className="message-header">
                  <p>Awesome</p>
                  <button className="delete" aria-label="delete"></button>
                </div>
                <div className="message-body">
                  Thank you for your <strong>purchase</strong>. We have
                  received, your payment. Check the email
                  for ticket details
                </div>
              </article>
            </div>
          </>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentSettings;